/* [data-theme='compact'] .site-navigation-steps,
.site-navigation-steps {
  /* margin-bottom: 60px; */
  /* box-shadow: 0px -1px 0 0 #e8e8e8 inset; */
/* } */


@media only screen and (max-width: 575px){
.onboarding-step-1 {
  min-height: calc(100vh + 300px);
}
}

@media only screen and (max-width: 639px) {

  .onboarding-step-2 {
    min-height: calc(100vh + 2500px);
  }
  }

@media only screen and  (min-width: 640px) and (max-width: 924px){
  .onboarding-step-2 {
    min-height: calc(100vh + 2250px);
  }
  }

  @media only screen and (min-width: 925px) and (max-width: 1339px) {

    .onboarding-step-2 {
      min-height: calc(100vh + 1600px);
    }
    }
    @media only screen and (min-width: 1340px) {

      .onboarding-step-2 {
        min-height: calc(100vh + 1000px);
      }
      }
    

      /* .onboarding-step-1 {
        min-height: calc(100vh);
      } */
      
      
      

  
  /* @media only screen and (max-width: 1339px){
    .onboarding-step-2 {
      min-height: calc(100vh + 2000px);
    }
    } */
  

