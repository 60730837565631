.option-box-rectangle {
    box-sizing: border-box;
    /* margin: 0; */
    padding: 10px 20px;
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    width: 350px;
    border-radius: 15px;
    font-weight: bold;
}

.option-box-rectangle-small {
    box-sizing: border-box;
    /* margin: 0; */
    padding: 15px;
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    width: 350px;
    border-radius: 15px;
    font-weight: bold;
}

.option-box-square {
    box-sizing: border-box;
    margin: 0;
    padding-top: 21px;
    padding-left: 25px;
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    width: 350px;
    border-radius: 15px;
    font-weight: bold;
    width: 98px;
}

.option-box-selected {
    background-color: #03a9f4 !important;
    color: white !important;
    -webkit-transition: background-color 300ms linear;
    -moz-transition: background-color 300ms linear;
    -o-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
}

.option-box-correct {
    background-color: #219653 !important;
    color: white !important;
    -webkit-transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;

}

.option-box-neutral {
    background-color: white !important;
    color: rgba(0, 0, 0, 0.85) !important;
    -webkit-transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;

}


.option-box-incorrect {
    background-color: #f17979 !important;
    color: white !important;
    opacity: 0.5 !important;
}

.option-box-dontknow {
    background-color: #4299e1 !important;
    color: white !important;
    opacity: 0.5 !important;
}

.options-body-nolabel {
    display: table-cell;
    width: inherit;
    /* height: 200px; */
    padding: 10px;
    vertical-align: middle;
    font-size: large;
}

.question-input-text-small {
    box-sizing: border-box;
    /* margin: 0; */
    padding: 15px;
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum", "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    /* width: 290px; */
    border-radius: 15px;
    font-weight: bold;
    border-width: 1px;
    height: 60px;
    font-size: large;
    /* text-align: center; */
    /* text-transform: uppercase; */
}

.rounded-box {
    border-radius: 15px;
}

.text-answer-input {
    box-sizing: border-box;
    /* margin: 0; */
    padding: 15px;
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum", "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    width: 350px;
    border-radius: 15px;
    font-weight: bold;
    border-width: 1px;
    height: 80px;
    font-size: large;
    text-align: center;
    text-transform: uppercase;
}


.options-body {
    display: table-cell;
    width: 250px;
    /* height: 200px; */
    padding: 10px;
    /* border: 3px transparent #1c87c9; */
    vertical-align: middle;
    font-size: large;
    text-align: left;
}


.option-box-unselected {
    /* background-color: #2F80ED; */
    /* color: white; */
    opacity: 0.5;
}

.option-box-semi-rectangle {
    box-sizing: border-box;
    /* margin-top: 30px; */
    padding: 15px;
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    width: 230px;
    border-radius: 15px;
    font-weight: bold;

}



.option-box:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
    cursor: pointer;
    transition: box-shadow .3s, border-color .3s;
}

.option-box {
    -webkit-transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;

}

.optionLabel {
    margin: 0 15px;
    font-size: "24px";
}

.empty-container {
    margin: 20px auto;
    width: 400px;
    height: 400px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 200px 200px;
    grid-row: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.empty-container .empty-box {
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-family: sans-serif;
}