.table {
    width: 70vw;
}

.classesList > span {
    padding: 10px;
}

.classesList .header {
    background: #FAFAFA;
}

.bold-heading {
    font-size: 1rem;
    font-weight: 800;
}

/* .classesList .actionButtons {
    visibility: hidden;
} */
