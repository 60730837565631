.profile-menu .ant-menu-item-selected::after {
    border-bottom: 0
}

.appHeader {
    min-height: 64px;
    height: auto;
}


@media only screen and (min-width: 600px) {
    .student-email-add {
        min-width: 300px;
    }

}

@media only screen and (min-width: 700px) {
    .app-top-menu {
        min-width: 600px;
    }

}

@media only screen and (max-width: 700px) {
    .app-top-menu {
        width: 100px;
    }
}

.app-top-menu {
    position: fixed;
    right: 0;
}