.box {
    position: relative;
}

.circle_ripple {
    height: 50px;
    width: 50px;
    background: #1890ff;
    border-radius: 50%;
    -webkit-animation-name: ripple 2s infinite;
    animation: ripple 2s infinite;
    position: absolute;
    left: -6px;
    top: -6px;
    z-index: 0
}

.circle_ripple-2 {
    height: 50px;
    width: 50px;
    background: #1890ff;
    border-radius: 50%;
    -webkit-animation-name: ripple 2s infinite;
    animation: ripple-2 2s infinite;
    position: absolute;
    left: -6px;
    top: -6px;
}

@keyframes ripple {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(2);
        opacity: 0.3;
    }

    100% {
        transform: scale(1);

    }
}

@keyframes ripple-2 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(2.5);
        opacity: 0.3;
    }

    100% {
        transform: scale(1);
    }
}


.circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #EAEAEA;
    /* position: absolute; */
    left: 0;
    top: 100px;

}

.circle-2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ffffff;
    /* position: absolute; */
    left: 5px;
    top: 5px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    z-index: 2;
}

.circle-2 .anticon {
    font-size: 20px;
    color: #bdbdbd;
    position: absolute;
    left: 18px;
    top: 17px;
    z-index: 10
}

.recorder-mic {
    border-radius: 50%;
    border-color: #bdbdbd;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    color: #bdbdbd;
    position: relative;
}

.active-mic {
    border-color: #e30000;
    color: #fff;
}


.recorder-mic:hover {
    border-color: #dbdada;
    color: #b9b7b7;

}


.progress {
    width: 60px;
    height: 60px;
    left: 0px;
    top: 100px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: absolute;
    z-index: 1;
}

.progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #e7e7e7;
    position: absolute;
    top: 0;
    left: 0;
}

.progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.progress .progress-left {
    left: 0;
}

.progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.progress .progress-right {
    right: 0;
}

.progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: transparent;
    font-size: 24px;
    color: #fff;
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
}

.progress.blue .progress-bar {
    border-color: #36B37E;
}

.progress.blue .progress-left .progress-bar {
    animation: loading-1 5s linear forwards 1.8s;
}

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
    }
}

@keyframes loading-3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@keyframes loading-4 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
    }
}

@keyframes loading-5 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(126deg);
        transform: rotate(126deg);
    }
}

@media only screen and (max-width: 990px) {
    .progress {
        margin-bottom: 20px;
    }
}