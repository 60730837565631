.hover-red :hover {
    color: #c53030;
}

.question-card {
    width: 350px;
    min-width: 350px;
    min-width: calc(100% - 26px);
    max-width: 450px;
    overflow: auto;
    max-height: 350px;
    cursor: pointer;

}

.fixed-height-card {
    /* height: 400px; */
}


.question-card1:hover {
    overflow: scroll;
    height: auto;
    max-height: 900px;
    transition: max-height 0.5s ease-in-out;
}