@import url(https://fonts.googleapis.com/css?family=Comfortaa:regular,bold,italic);
/* 
@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities"; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  background-image: radial-gradient(rgb(185, 183, 177) 5%, transparent 0);
  background-size: 20px 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Question-Container-height {
  height: calc(100vh - 100px);
}

.padding-top-1 {
  padding-top: 1em;
}

.appHeader {
  z-index: 1;
}

@media only screen and (max-width: 660px){
  .sessionTitle {
    width: 110px;
  }
}

@media only screen and (min-width: 660px) and (max-width: 959px){
  .sessionTitle {
    width: 350px;
  }
}

@media only screen and (min-width: 959px) and (max-width: 1280px){
  .sessionTitle {
    width: 600px;
  }
}

.primary-btn {
  z-index: 50;
  width: 12rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255,255,255,var(--text-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 800;
  border-radius: 0.5rem;
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66,153,225,var(--bg-opacity));
}

.disabled-btn {
  z-index: 50;
  width: 12rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255,255,255,var(--text-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 800;
  border-radius: 0.5rem;
  cursor: auto;
  --bg-opacity: 1;
  background-color: #a0aec0;
  /* background-color: rgba(66,153,225,var(--bg-opacity)); */
}
.profile-menu .ant-menu-item-selected::after {
  border-bottom: 0
}

.appHeader {
  min-height: 64px;
  height: auto;
}

@media only screen and (min-width: 600px) {
  .student-email-add {
    min-width: 300px;
  }
}

@media only screen and (min-width: 700px) {
  .app-top-menu {
    min-width: 600px;
  }
}

@media only screen and (max-width: 700px) {
  .app-top-menu {
    width: 100px;
  }
}

.app-top-menu {
  position: fixed;
  right: 0;
}
.table {
  width: 70vw;
}

.classesList > span {
  padding: 10px;
}

.classesList .header {
  background: #FAFAFA;
}

.bold-heading {
  font-size: 1rem;
  font-weight: 800;
}

/* .classesList .actionButtons {
    visibility: hidden;
} */

.vidya-page-layout {
  min-height: 100vh;
}
.option-box-rectangle {
  box-sizing: border-box;
  /* margin: 0; */
  padding: 10px 20px;
  background-color: white;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", "tnum", "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  width: 350px;
  border-radius: 15px;
  font-weight: bold;
}

.option-box-rectangle-small {
  box-sizing: border-box;
  /* margin: 0; */
  padding: 15px;
  background-color: white;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", "tnum", "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  width: 350px;
  border-radius: 15px;
  font-weight: bold;
}

.option-box-square {
  box-sizing: border-box;
  margin: 0;
  padding-top: 21px;
  padding-left: 25px;
  background-color: white;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", "tnum", "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  width: 350px;
  border-radius: 15px;
  font-weight: bold;
  width: 98px;
}

.option-box-selected {
  background-color: #03a9f4 !important;
  color: white !important;
  transition: background-color 300ms linear;
}

.option-box-correct {
  background-color: #219653 !important;
  color: white !important;
  transition: background-color 200ms linear;
}

.option-box-neutral {
  background-color: white !important;
  color: rgba(0, 0, 0, .85) !important;
  transition: background-color 200ms linear;
}

.option-box-incorrect {
  background-color: #f17979 !important;
  color: white !important;
  opacity: 0.5 !important;
}

.option-box-dontknow {
  background-color: #4299e1 !important;
  color: white !important;
  opacity: 0.5 !important;
}

.options-body-nolabel {
  display: table-cell;
  width: inherit;
  /* height: 200px; */
  padding: 10px;
  vertical-align: middle;
  font-size: large;
}

.question-input-text-small {
  box-sizing: border-box;
  /* margin: 0; */
  padding: 15px;
  background-color: white;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", "tnum", "tnum", "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  /* width: 290px; */
  border-radius: 15px;
  font-weight: bold;
  border-width: 1px;
  height: 60px;
  font-size: large;
  /* text-align: center; */
  /* text-transform: uppercase; */
}

.rounded-box {
  border-radius: 15px;
}

.text-answer-input {
  box-sizing: border-box;
  /* margin: 0; */
  padding: 15px;
  background-color: white;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", "tnum", "tnum", "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  width: 350px;
  border-radius: 15px;
  font-weight: bold;
  border-width: 1px;
  height: 80px;
  font-size: large;
  text-align: center;
  text-transform: uppercase;
}

.options-body {
  display: table-cell;
  width: 250px;
  /* height: 200px; */
  padding: 10px;
  /* border: 3px transparent #1c87c9; */
  vertical-align: middle;
  font-size: large;
  text-align: left;
}

.option-box-unselected {
  /* background-color: #2F80ED; */
  /* color: white; */
  opacity: 0.5;
}

.option-box-semi-rectangle {
  box-sizing: border-box;
  /* margin-top: 30px; */
  padding: 15px;
  background-color: white;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", "tnum", "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  width: 230px;
  border-radius: 15px;
  font-weight: bold;
}

.option-box:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16078), 0 3px 6px rgba(0, 0, 0, 0.12157), 0 5px 12px 4px rgba(0, 0, 0, 0.0902);
  cursor: pointer;
  transition: box-shadow .3s, border-color .3s;
}

.option-box {
  transition: background-color 200ms linear;
}

.optionLabel {
  margin: 0 15px;
  font-size: "24px";
}

.empty-container {
  margin: 20px auto;
  width: 400px;
  height: 400px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 200px 200px;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.empty-container .empty-box {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-family: sans-serif;
}
.box {
  position: relative;
}

.circle_ripple {
  height: 50px;
  width: 50px;
  background: #1890ff;
  border-radius: 50%;
  -webkit-animation-name: ripple 2s infinite;
  animation: ripple 2s infinite;
  position: absolute;
  left: -6px;
  top: -6px;
  z-index: 0
}

.circle_ripple-2 {
  height: 50px;
  width: 50px;
  background: #1890ff;
  border-radius: 50%;
  -webkit-animation-name: ripple 2s infinite;
  animation: ripple-2 2s infinite;
  position: absolute;
  left: -6px;
  top: -6px;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
    opacity: 0.3;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes ripple-2 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2.5);
    opacity: 0.3;
  }

  100% {
    transform: scale(1);
  }
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #EAEAEA;
  /* position: absolute; */
  left: 0;
  top: 100px;
}

.circle-2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ffffff;
  /* position: absolute; */
  left: 5px;
  top: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, .16);
  z-index: 2;
}

.circle-2 .anticon {
  font-size: 20px;
  color: #bdbdbd;
  position: absolute;
  left: 18px;
  top: 17px;
  z-index: 10
}

.recorder-mic {
  border-radius: 50%;
  border-color: #bdbdbd;
  border-width: 2px;
  border-style: solid;
  padding: 10px;
  color: #bdbdbd;
  position: relative;
}

.active-mic {
  border-color: #e30000;
  color: #fff;
}

.recorder-mic:hover {
  border-color: #dbdada;
  color: #b9b7b7;
}

.progress {
  width: 60px;
  height: 60px;
  left: 0px;
  top: 100px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: absolute;
  z-index: 1;
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #e7e7e7;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 5px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: transparent;
  font-size: 24px;
  color: #fff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}

.progress.blue .progress-bar {
  border-color: #36B37E;
}

.progress.blue .progress-left .progress-bar {
  animation: loading-1 5s linear forwards 1.8s;
}

@keyframes loading-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(144deg);
  }
}

@keyframes loading-3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg);
  }
}

@keyframes loading-4 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(36deg);
  }
}

@keyframes loading-5 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(126deg);
  }
}

@media only screen and (max-width: 990px) {
  .progress {
    margin-bottom: 20px;
  }
}
.border-bottom-thin {
  border-bottom: 1px solid #F0F0F0;
}

.text-blue {
  color: #1890FF;
}

.border-bottom-blue {
  border-bottom: 2px solid #1890FF;
}

.bg-header {
  background: #F0F0F0;
}

.absent-marker {
  margin-bottom: 2px;
  height: 7px;
  width: 7px;
  background-color: rgba(247, 0, 0, 0.67843);
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}

.present-marker {
  margin-bottom: 2px;
  height: 7px;
  width: 7px;
  background-color: #52c41a;
  opacity: 0;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
.library-tags .ant-select-selector {
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
}
/* [data-theme='compact'] .site-navigation-steps,
.site-navigation-steps {
  /* margin-bottom: 60px; */

/* box-shadow: 0px -1px 0 0 #e8e8e8 inset; */

/* } */

@media only screen and (max-width: 575px){
  .onboarding-step-1 {
    min-height: calc(100vh + 300px);
  }
}

@media only screen and (max-width: 639px) {
  .onboarding-step-2 {
    min-height: calc(100vh + 2500px);
  }
}

@media only screen and  (min-width: 640px) and (max-width: 924px){
  .onboarding-step-2 {
    min-height: calc(100vh + 2250px);
  }
}

@media only screen and (min-width: 925px) and (max-width: 1339px) {
  .onboarding-step-2 {
    min-height: calc(100vh + 1600px);
  }
}

@media only screen and (min-width: 1340px) {
  .onboarding-step-2 {
    min-height: calc(100vh + 1000px);
  }
}

/* .onboarding-step-1 {
        min-height: calc(100vh);
      } */

/* @media only screen and (max-width: 1339px){
    .onboarding-step-2 {
      min-height: calc(100vh + 2000px);
    }
    } */
  


.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

.background {
  background: #f9f9f9;
  /* background-image: radial-gradient(rgb(185, 183, 177) 5%, transparent 0);
  background-size: 20px 20px; */
}

/* .moving-background {
  background: #f9f9f9;
  background-image: radial-gradient(rgb(185, 183, 177) 5%, transparent 0);
  background-size: 20px 20px;

} */

.session .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
  color: rgba(245, 222, 179, .471) !important;
}

.session .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: wheat;
}

.session .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: wheat;
}

.session .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
  color: rgba(245, 222, 179, .471);
}

.blinkingText {
  animation: blinkingDescription 2s infinite;
}

@keyframes blinkingDescription {
  0% {
    color: rgba(245, 222, 179, .0)
  }

  /* 25% {
    color: rgba(245, 222, 179, 0.5)
  } */

  50% {
    color: rgba(245, 222, 179, 1)
  }

  /* 75% {
    color: rgba(245, 222, 179, 0.5)
  } */

  100% {
    color: rgba(245, 222, 179, 0)
  }
}

.h-100vh {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

img,
svg {
  display: unset;
  vertical-align: unset;
}

body {
  font-family: 'Comfortaa';
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.4;
    border-radius: 20%;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 90%;
  }
}

.moving-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  /* background: #c8355d; */
  background: #111827;
  overflow: hidden;
}

.moving-background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, .2);
  animation: animate 50s linear infinite;
}

.moving-background li:nth-child(0) {
  left: 27%;
  width: 6px;
  height: 6px;
  bottom: -6px;
  animation-delay: 1s;
}

.moving-background li:nth-child(1) {
  left: 10%;
  width: 1px;
  height: 1px;
  bottom: -1px;
  animation-delay: 1s;
}

.moving-background li:nth-child(2) {
  left: 24%;
  width: 11px;
  height: 11px;
  bottom: -11px;
  animation-delay: 8s;
}

.moving-background li:nth-child(3) {
  left: 23%;
  width: 5px;
  height: 5px;
  bottom: -5px;
  animation-delay: 10s;
}

.moving-background li:nth-child(4) {
  left: 57%;
  width: 1px;
  height: 1px;
  bottom: -1px;
  animation-delay: 18s;
}

.moving-background li:nth-child(5) {
  left: 54%;
  width: 2px;
  height: 2px;
  bottom: -2px;
  animation-delay: 2s;
}

.moving-background li:nth-child(6) {
  left: 22%;
  width: 2px;
  height: 2px;
  bottom: -2px;
  animation-delay: 24s;
}

.moving-background li:nth-child(7) {
  left: 38%;
  width: 17px;
  height: 17px;
  bottom: -17px;
  animation-delay: 23s;
}

.moving-background li:nth-child(8) {
  left: 23%;
  width: 30px;
  height: 30px;
  bottom: -30px;
  animation-delay: 15s;
}

.moving-background li:nth-child(9) {
  left: 40%;
  width: 17px;
  height: 17px;
  bottom: -17px;
  animation-delay: 43s;
}

.moving-background li:nth-child(10) {
  left: 72%;
  width: 29px;
  height: 29px;
  bottom: -29px;
  animation-delay: 26s;
}

.moving-background li:nth-child(11) {
  left: 33%;
  width: 7px;
  height: 7px;
  bottom: -7px;
  animation-delay: 30s;
}

.moving-background li:nth-child(12) {
  left: 69%;
  width: 13px;
  height: 13px;
  bottom: -13px;
  animation-delay: 20s;
}

.moving-background li:nth-child(13) {
  left: 17%;
  width: 22px;
  height: 22px;
  bottom: -22px;
  animation-delay: 11s;
}

.moving-background li:nth-child(14) {
  left: 25%;
  width: 26px;
  height: 26px;
  bottom: -26px;
  animation-delay: 9s;
}

.moving-background li:nth-child(15) {
  left: 89%;
  width: 29px;
  height: 29px;
  bottom: -29px;
  animation-delay: 31s;
}

.moving-background li:nth-child(16) {
  left: 6%;
  width: 14px;
  height: 14px;
  bottom: -14px;
  animation-delay: 68s;
}

.moving-background li:nth-child(17) {
  left: 14%;
  width: 34px;
  height: 34px;
  bottom: -34px;
  animation-delay: 37s;
}

.moving-background li:nth-child(18) {
  left: 53%;
  width: 24px;
  height: 24px;
  bottom: -24px;
  animation-delay: 21s;
}

.moving-background li:nth-child(19) {
  left: 4%;
  width: 20px;
  height: 20px;
  bottom: -20px;
  animation-delay: 56s;
}

.moving-background li:nth-child(20) {
  left: 76%;
  width: 28px;
  height: 28px;
  bottom: -28px;
  animation-delay: 61s;
}

.moving-background li:nth-child(21) {
  left: 69%;
  width: 8px;
  height: 8px;
  bottom: -8px;
  animation-delay: 98s;
}

.moving-background li:nth-child(22) {
  left: 47%;
  width: 5px;
  height: 5px;
  bottom: -5px;
  animation-delay: 56s;
}

.moving-background li:nth-child(23) {
  left: 42%;
  width: 8px;
  height: 8px;
  bottom: -8px;
  animation-delay: 73s;
}

.moving-background li:nth-child(24) {
  left: 63%;
  width: 14px;
  height: 14px;
  bottom: -14px;
  animation-delay: 18s;
}

.moving-background li:nth-child(25) {
  left: 77%;
  width: 2px;
  height: 2px;
  bottom: -2px;
  animation-delay: 99s;
}

.moving-background li:nth-child(26) {
  left: 7%;
  width: 9px;
  height: 9px;
  bottom: -9px;
  animation-delay: 54s;
}

.moving-background li:nth-child(27) {
  left: 45%;
  width: 20px;
  height: 20px;
  bottom: -20px;
  animation-delay: 115s;
}

.moving-background li:nth-child(28) {
  left: 16%;
  width: 1px;
  height: 1px;
  bottom: -1px;
  animation-delay: 16s;
}

.moving-background li:nth-child(29) {
  left: 60%;
  width: 15px;
  height: 15px;
  bottom: -15px;
  animation-delay: 13s;
}

.moving-background li:nth-child(30) {
  left: 14%;
  width: 17px;
  height: 17px;
  bottom: -17px;
  animation-delay: 13s;
}

.moving-background li:nth-child(31) {
  left: 69%;
  width: 11px;
  height: 11px;
  bottom: -11px;
  animation-delay: 53s;
}

.moving-background li:nth-child(32) {
  left: 45%;
  width: 5px;
  height: 5px;
  bottom: -5px;
  animation-delay: 82s;
}

.moving-background li:nth-child(33) {
  left: 14%;
  width: 15px;
  height: 15px;
  bottom: -15px;
  animation-delay: 21s;
}

.moving-background li:nth-child(34) {
  left: 86%;
  width: 35px;
  height: 35px;
  bottom: -35px;
  animation-delay: 66s;
}

.moving-background li:nth-child(35) {
  left: 30%;
  width: 21px;
  height: 21px;
  bottom: -21px;
  animation-delay: 72s;
}

.moving-background li:nth-child(36) {
  left: 49%;
  width: 33px;
  height: 33px;
  bottom: -33px;
  animation-delay: 178s;
}

.moving-background li:nth-child(37) {
  left: 19%;
  width: 16px;
  height: 16px;
  bottom: -16px;
  animation-delay: 137s;
}

.moving-background li:nth-child(38) {
  left: 17%;
  width: 26px;
  height: 26px;
  bottom: -26px;
  animation-delay: 111s;
}

.moving-background li:nth-child(39) {
  left: 68%;
  width: 36px;
  height: 36px;
  bottom: -36px;
  animation-delay: 165s;
}

.moving-background li:nth-child(40) {
  left: 36%;
  width: 19px;
  height: 19px;
  bottom: -19px;
  animation-delay: 23s;
}

.moving-background li:nth-child(41) {
  left: 21%;
  width: 4px;
  height: 4px;
  bottom: -4px;
  animation-delay: 152s;
}

.moving-background li:nth-child(42) {
  left: 24%;
  width: 26px;
  height: 26px;
  bottom: -26px;
  animation-delay: 173s;
}

.moving-background li:nth-child(43) {
  left: 2%;
  width: 21px;
  height: 21px;
  bottom: -21px;
  animation-delay: 215s;
}

.moving-background li:nth-child(44) {
  left: 10%;
  width: 30px;
  height: 30px;
  bottom: -30px;
  animation-delay: 46s;
}

.moving-background li:nth-child(45) {
  left: 48%;
  width: 2px;
  height: 2px;
  bottom: -2px;
  animation-delay: 201s;
}

.moving-background li:nth-child(46) {
  left: 79%;
  width: 19px;
  height: 19px;
  bottom: -19px;
  animation-delay: 207s;
}

.moving-background li:nth-child(47) {
  left: 21%;
  width: 15px;
  height: 15px;
  bottom: -15px;
  animation-delay: 147s;
}

.moving-background li:nth-child(48) {
  left: 27%;
  width: 7px;
  height: 7px;
  bottom: -7px;
  animation-delay: 12s;
}

.moving-background li:nth-child(49) {
  left: 50%;
  width: 2px;
  height: 2px;
  bottom: -2px;
  animation-delay: 200s;
}
.hover-red :hover {
  color: #c53030;
}

.question-card {
  width: 350px;
  min-width: 350px;
  min-width: calc(100% - 26px);
  max-width: 450px;
  overflow: auto;
  max-height: 350px;
  cursor: pointer;
}

.fixed-height-card {
  /* height: 400px; */
}

.question-card1:hover {
  overflow: scroll;
  height: auto;
  max-height: 900px;
  transition: max-height 0.5s ease-in-out;
}
#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, .3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-sider {
  /* height: calc(100vh - 64px) */
}

.ant-layout-sider .ant-layout-sider-trigger {
  background-color: #373738;
  bottom: 25px
}

@media only screen and (min-width: 992px) {
  .ant-layout-sider .ant-layout-sider-trigger {
    display: none;
  }

  .ant-layout-sider-has-trigger {
    padding-bottom: 0px;
  }
}

#student-bulk-upload .CSVImporter_FileSelector {
  border: .1em dashed #000;
  padding: 4em;
  border-radius: .4em;
  background: #fbfbfb;
  text-align: center;
  color: #202020;
  cursor: pointer;
}

#rc-tabs-0-panel-2 .student-details {
  display: block;
}
