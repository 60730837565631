.border-bottom-thin {
  border-bottom: 1px solid #F0F0F0;
}

.text-blue {
  color: #1890FF;
}

.border-bottom-blue {
  border-bottom: 2px solid #1890FF;
}

.bg-header {
  background: #F0F0F0;
}

.absent-marker {
  margin-bottom: 2px;
    height: 7px;
    width: 7px;
    background-color: #f70000ad;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
}

.present-marker {
  margin-bottom: 2px;
    height: 7px;
    width: 7px;
    background-color: #52c41a;
    opacity: 0;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
}