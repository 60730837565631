@import url("https://fonts.googleapis.com/css?family=Comfortaa:regular,bold,italic");


.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

.background {
  background: #f9f9f9;
  /* background-image: radial-gradient(rgb(185, 183, 177) 5%, transparent 0);
  background-size: 20px 20px; */

}

/* .moving-background {
  background: #f9f9f9;
  background-image: radial-gradient(rgb(185, 183, 177) 5%, transparent 0);
  background-size: 20px 20px;

} */

.session .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
  color: rgba(245, 222, 179, 0.471) !important;
}

.session .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: wheat;
}

.session .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: wheat;

}

.session .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
  color: rgba(245, 222, 179, 0.471);
}

.blinkingText {
  animation: blinkingDescription 2s infinite;
}

@keyframes blinkingDescription {
  0% {
    color: rgba(245, 222, 179, 0.0)
  }

  /* 25% {
    color: rgba(245, 222, 179, 0.5)
  } */

  50% {
    color: rgba(245, 222, 179, 1)
  }

  /* 75% {
    color: rgba(245, 222, 179, 0.5)
  } */

  100% {
    color: rgba(245, 222, 179, 0)
  }

}


.h-100vh {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

img,
svg {
  display: unset;
  vertical-align: unset;
}

body {
  font-family: 'Comfortaa';
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.4;
    border-radius: 20%;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 90%;
  }
}

.moving-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  /* background: #c8355d; */
  background: #111827;
  overflow: hidden;
}

.moving-background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 50s linear infinite;
}




.moving-background li:nth-child(0) {
  left: 27%;
  width: 6px;
  height: 6px;
  bottom: -6px;
  animation-delay: 1s;
}

.moving-background li:nth-child(1) {
  left: 10%;
  width: 1px;
  height: 1px;
  bottom: -1px;
  animation-delay: 1s;
}

.moving-background li:nth-child(2) {
  left: 24%;
  width: 11px;
  height: 11px;
  bottom: -11px;
  animation-delay: 8s;
}

.moving-background li:nth-child(3) {
  left: 23%;
  width: 5px;
  height: 5px;
  bottom: -5px;
  animation-delay: 10s;
}

.moving-background li:nth-child(4) {
  left: 57%;
  width: 1px;
  height: 1px;
  bottom: -1px;
  animation-delay: 18s;
}

.moving-background li:nth-child(5) {
  left: 54%;
  width: 2px;
  height: 2px;
  bottom: -2px;
  animation-delay: 2s;
}

.moving-background li:nth-child(6) {
  left: 22%;
  width: 2px;
  height: 2px;
  bottom: -2px;
  animation-delay: 24s;
}

.moving-background li:nth-child(7) {
  left: 38%;
  width: 17px;
  height: 17px;
  bottom: -17px;
  animation-delay: 23s;
}

.moving-background li:nth-child(8) {
  left: 23%;
  width: 30px;
  height: 30px;
  bottom: -30px;
  animation-delay: 15s;
}

.moving-background li:nth-child(9) {
  left: 40%;
  width: 17px;
  height: 17px;
  bottom: -17px;
  animation-delay: 43s;
}

.moving-background li:nth-child(10) {
  left: 72%;
  width: 29px;
  height: 29px;
  bottom: -29px;
  animation-delay: 26s;
}

.moving-background li:nth-child(11) {
  left: 33%;
  width: 7px;
  height: 7px;
  bottom: -7px;
  animation-delay: 30s;
}

.moving-background li:nth-child(12) {
  left: 69%;
  width: 13px;
  height: 13px;
  bottom: -13px;
  animation-delay: 20s;
}

.moving-background li:nth-child(13) {
  left: 17%;
  width: 22px;
  height: 22px;
  bottom: -22px;
  animation-delay: 11s;
}

.moving-background li:nth-child(14) {
  left: 25%;
  width: 26px;
  height: 26px;
  bottom: -26px;
  animation-delay: 9s;
}

.moving-background li:nth-child(15) {
  left: 89%;
  width: 29px;
  height: 29px;
  bottom: -29px;
  animation-delay: 31s;
}

.moving-background li:nth-child(16) {
  left: 6%;
  width: 14px;
  height: 14px;
  bottom: -14px;
  animation-delay: 68s;
}

.moving-background li:nth-child(17) {
  left: 14%;
  width: 34px;
  height: 34px;
  bottom: -34px;
  animation-delay: 37s;
}

.moving-background li:nth-child(18) {
  left: 53%;
  width: 24px;
  height: 24px;
  bottom: -24px;
  animation-delay: 21s;
}

.moving-background li:nth-child(19) {
  left: 4%;
  width: 20px;
  height: 20px;
  bottom: -20px;
  animation-delay: 56s;
}

.moving-background li:nth-child(20) {
  left: 76%;
  width: 28px;
  height: 28px;
  bottom: -28px;
  animation-delay: 61s;
}

.moving-background li:nth-child(21) {
  left: 69%;
  width: 8px;
  height: 8px;
  bottom: -8px;
  animation-delay: 98s;
}

.moving-background li:nth-child(22) {
  left: 47%;
  width: 5px;
  height: 5px;
  bottom: -5px;
  animation-delay: 56s;
}

.moving-background li:nth-child(23) {
  left: 42%;
  width: 8px;
  height: 8px;
  bottom: -8px;
  animation-delay: 73s;
}

.moving-background li:nth-child(24) {
  left: 63%;
  width: 14px;
  height: 14px;
  bottom: -14px;
  animation-delay: 18s;
}

.moving-background li:nth-child(25) {
  left: 77%;
  width: 2px;
  height: 2px;
  bottom: -2px;
  animation-delay: 99s;
}

.moving-background li:nth-child(26) {
  left: 7%;
  width: 9px;
  height: 9px;
  bottom: -9px;
  animation-delay: 54s;
}

.moving-background li:nth-child(27) {
  left: 45%;
  width: 20px;
  height: 20px;
  bottom: -20px;
  animation-delay: 115s;
}

.moving-background li:nth-child(28) {
  left: 16%;
  width: 1px;
  height: 1px;
  bottom: -1px;
  animation-delay: 16s;
}

.moving-background li:nth-child(29) {
  left: 60%;
  width: 15px;
  height: 15px;
  bottom: -15px;
  animation-delay: 13s;
}

.moving-background li:nth-child(30) {
  left: 14%;
  width: 17px;
  height: 17px;
  bottom: -17px;
  animation-delay: 13s;
}

.moving-background li:nth-child(31) {
  left: 69%;
  width: 11px;
  height: 11px;
  bottom: -11px;
  animation-delay: 53s;
}

.moving-background li:nth-child(32) {
  left: 45%;
  width: 5px;
  height: 5px;
  bottom: -5px;
  animation-delay: 82s;
}

.moving-background li:nth-child(33) {
  left: 14%;
  width: 15px;
  height: 15px;
  bottom: -15px;
  animation-delay: 21s;
}

.moving-background li:nth-child(34) {
  left: 86%;
  width: 35px;
  height: 35px;
  bottom: -35px;
  animation-delay: 66s;
}

.moving-background li:nth-child(35) {
  left: 30%;
  width: 21px;
  height: 21px;
  bottom: -21px;
  animation-delay: 72s;
}

.moving-background li:nth-child(36) {
  left: 49%;
  width: 33px;
  height: 33px;
  bottom: -33px;
  animation-delay: 178s;
}

.moving-background li:nth-child(37) {
  left: 19%;
  width: 16px;
  height: 16px;
  bottom: -16px;
  animation-delay: 137s;
}

.moving-background li:nth-child(38) {
  left: 17%;
  width: 26px;
  height: 26px;
  bottom: -26px;
  animation-delay: 111s;
}

.moving-background li:nth-child(39) {
  left: 68%;
  width: 36px;
  height: 36px;
  bottom: -36px;
  animation-delay: 165s;
}

.moving-background li:nth-child(40) {
  left: 36%;
  width: 19px;
  height: 19px;
  bottom: -19px;
  animation-delay: 23s;
}

.moving-background li:nth-child(41) {
  left: 21%;
  width: 4px;
  height: 4px;
  bottom: -4px;
  animation-delay: 152s;
}

.moving-background li:nth-child(42) {
  left: 24%;
  width: 26px;
  height: 26px;
  bottom: -26px;
  animation-delay: 173s;
}

.moving-background li:nth-child(43) {
  left: 2%;
  width: 21px;
  height: 21px;
  bottom: -21px;
  animation-delay: 215s;
}

.moving-background li:nth-child(44) {
  left: 10%;
  width: 30px;
  height: 30px;
  bottom: -30px;
  animation-delay: 46s;
}

.moving-background li:nth-child(45) {
  left: 48%;
  width: 2px;
  height: 2px;
  bottom: -2px;
  animation-delay: 201s;
}

.moving-background li:nth-child(46) {
  left: 79%;
  width: 19px;
  height: 19px;
  bottom: -19px;
  animation-delay: 207s;
}

.moving-background li:nth-child(47) {
  left: 21%;
  width: 15px;
  height: 15px;
  bottom: -15px;
  animation-delay: 147s;
}

.moving-background li:nth-child(48) {
  left: 27%;
  width: 7px;
  height: 7px;
  bottom: -7px;
  animation-delay: 12s;
}

.moving-background li:nth-child(49) {
  left: 50%;
  width: 2px;
  height: 2px;
  bottom: -2px;
  animation-delay: 200s;
}