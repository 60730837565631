

#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  
  .site-layout-background {
    background: #fff;
  }

  .ant-layout-sider { 
    /* height: calc(100vh - 64px) */
  }

  .ant-layout-sider .ant-layout-sider-trigger {
    background-color: #373738;
    bottom: 25px 
  }
  @media only screen and (min-width: 992px) {
    .ant-layout-sider .ant-layout-sider-trigger {
      display: none;
    }
    .ant-layout-sider-has-trigger {
      padding-bottom: 0px;
  }
    
    }

  
  #student-bulk-upload .CSVImporter_FileSelector {
    border: .1em dashed #000;  
    padding: 4em;
    border-radius: .4em;
    background: #fbfbfb;
    text-align: center;
    color: #202020;
    cursor: pointer;
  }

  #rc-tabs-0-panel-2 .student-details {
    display: block;
  }