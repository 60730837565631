@import '~antd/dist/antd.css';
/* 
@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities"; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  background-image: radial-gradient(rgb(185, 183, 177) 5%, transparent 0);
  background-size: 20px 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Question-Container-height {
  height: calc(100vh - 100px);
}

.padding-top-1 {
  padding-top: 1em;
}

.appHeader {
  z-index: 1;
}

@media only screen and (max-width: 660px){
  .sessionTitle {
    width: 110px;
  }  
}

@media only screen and (min-width: 660px) and (max-width: 959px){
    .sessionTitle {
    width: 350px;
  }  
}

@media only screen and (min-width: 959px) and (max-width: 1280px){
  .sessionTitle {
    width: 600px;
  }  
}

.primary-btn {
  z-index: 50;
  width: 12rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255,255,255,var(--text-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 800;
  border-radius: 0.5rem;
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66,153,225,var(--bg-opacity));
}

.disabled-btn {
  z-index: 50;
  width: 12rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255,255,255,var(--text-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 800;
  border-radius: 0.5rem;
  cursor: auto;
  --bg-opacity: 1;
  background-color: #a0aec0;
  /* background-color: rgba(66,153,225,var(--bg-opacity)); */
}